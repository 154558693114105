import { Component, OnInit } from '@angular/core';
declare var jQuery: any;
import 'src/assets/js/main.js'
@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    //background image teaser and secitons with half image bg

	
	//prettyPhoto
	if (jQuery().prettyPhoto) {
		jQuery("a[data-gal^='prettyPhoto']").prettyPhoto({
			hook: 'data-gal',
			theme: 'facebook', /* light_rounded / dark_rounded / light_square / dark_square / facebook / pp_default*/
			social_tools: false,
			default_width: 1170,
			default_height: 780
		});
	}
  }

}
