import { Component, OnInit } from '@angular/core';
declare var jQuery: any;
declare var window:any
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    jQuery(document).ready(function()
    {
      jQuery('.all-scr-cover .slide-image-wrap').each(function(){
        console.log('jquery called');
        var $curImageWrap = jQuery( this );
        var $curRoundedContainer = $curImageWrap.find('.rounded-container');
        var $curImage = $curImageWrap.find('img');
        var requiredImageWidth = $curImageWrap.height() * $curImage.width() / $curImage.height();
        if (!(requiredImageWidth < $curImageWrap.width())) {
          $curRoundedContainer.width(requiredImageWidth * 1.2);
        } else {
          $curRoundedContainer.width('120%');
        }
      })
    });
    
    //calculate main slider image cover width

  }

}
